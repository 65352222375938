import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const MasteringPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Music Production Tutorials | Articles and Videos | Jimmy Ether"
        ogDescription="Free music production tutorials including discussions about songwriting philosophy, recording techniques, mixing tips, releasing music, etc."
        image="https://jimmyether.com/images/music-production-tutorials.jpg"
        url="https://jimmyether.com/music-production-tutorials/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Music Production Tutorials</h1>
            <p>
              There are a ton of video tutorials out there on audio concepts that are completely free. You can find them all over YouTube, TikTok, and thousands of websites. It can get overwhelming when you are starting out. Particularly when the advice you find conflicts. A lot of what's out there is solid technical advice. Some is terrible and based on poor logic and magical thinking. Sometimes bad information even comes from successful names in the industry. And then there's the middle ground where its value to you is subjective.
            </p>
            <p>
              Sometimes what I say in this production tips tutorial series may come off as definitive or emphatic. It's because the topic is important to me. Often I learned these lessons the hard way. But, I'm not here to tell you what you should do with these tutorial videos. I will do my best to provide a reasoned opinion and offer many options. I will also link to white papers and supporting data whenever possible. You shouldn't take the advice I or anyone else provides as a given. Follow up by researching and trying things for yourself.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/music-production-tutorials.jpg" alt="Music Production Tutorials" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>
              What Kind Of Music Production Tutorials I Plan To Provide
            </h2>
            <p>
              Now and then, I will speak on technical issues where there seems to be a lot of confusion. Or on issues where disinformation is rampant. But, generally, I want to make video tutorial content that provides inspiration. Different approaches to making music. Music engineering has a ton of technical aspects, for sure. But the whole point is to create. To push boundaries. To put a new spin on old ideas. To come out of the left field. I want to provide music production tutorials that get you moving forward.
            </p>
            <p>
              So, sharing how I approach different ways of making music seems like a better use of our time. There are thousands of free tutorials on how to use a compressor or EQ. There's better people to teach you about music theory. I'd rather show you how to start, finish, and release a song. If some technical tips slip in here or there, fine. It's more important that you feel energized to make music. If I can also remove some of the insecurity you have around making music, fantastic. 
            </p>
            <h2>
              Musical Taste and Frame of Reference
            </h2>
            <p>
              There are universal truths with audio production. These production skills can transfer to all music. But a lot of sound design is subjective. Often, it comes down to production specialization. Understand where a music producer is coming from when considering their advice.
            </p>
            <p>
              I've worked in indie and underground rock for 30 years. Particularly styles derived from punk and metal. A lot of heavy, noisy guitar music. But I'm also a huge fan of hip hop. I'm more into boom bap/backpack and underground hip hop. I'm also a jazz aficionado.  I take a lot of mix cues from classic jazz recordings to achieve natural sounds. Even when the source is heavily processed and unnatural. I love R&B. I've also worked on a good bit of acoustic-based singer-songwriter stuff.
            </p>
            <p>
              I'm not well-versed in EDM, but I'm not averse to it. Sound is sound. I only bring it up because I do find myself unintentionally bumping heads with EDM producers from time to time. There is mainstream pop I appreciate. But I'm more inclined toward outsider and more experimental music. I'm not impressed by perceived successes around sales, awards, and popularity metrics. These are marketing topics, not production ones. I only care if the music is interesting.
            </p>
            <p>
              So, that's where I'm coming from. It doesn't mean I can't relate to and work with other styles.
            </p>
            <h2>
              Digital Audio Workstation Agnostic Music Production Tutorials
            </h2>
            <p>
              The music production tutorials I provide will mostly be DAW agnostic. Pretty much all digital audio workstations can do the same things these days. That said, I have my favorite tools and others that I'm admittedly not familiar with. I worked from years on Pro Tools, but abandoned it over a decade ago. I tend to work in Reaper and Universal Audio's Luna. I may provide some Luna workflow tutorials from time to time. It's a newer workstation that doesn't get as much attention as it should. I will also work with Native Instruments Maschine from time to time. And I will use Traktor Scratch with Technics turntables to manipulate sounds. But, you can transfer these production lessons to FL Studio, Ableton Live, Logic Pro, or whatever you prefer.
            </p>
            <h2>Recent Music Production Tutorials, Articles, and Videos</h2>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>LUFS and The Loudness War</h3>
            <p>
              For decades mastering engineers have fought The Loudness Wars. I deep-dive into why it happenend, if there is any merit, if it is actually over, and how I approach masterting today for streaming and HD.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/lufs-and-the-loudness-war/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-high-def.jpg" alt="LUFS and The Loudness War" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/CADM179.jpg" alt="Demonstration of CADM179 Microphone" />
          </div>
        }
        textRight={
          <div>
            <h3>Demonstration of CADM179 Microphone</h3>
            <p>
              Demonstration and review of the inexpensive CAD M179 Microphone with stereo examples used as a Blumlein pair.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/cad-m179-review/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default MasteringPage
